import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import Profile from './pages/Profile';
import TeamMain from './pages/teams/TeamMain';
import Ranking from './pages/teams/Ranking';
import Landing from './pages/Landing';
import MyTasks from './pages/MyTasks';



function App() {
  const userEmail = localStorage.getItem('email');

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={userEmail && userEmail !== '' ? <Navigate to="/home" /> : <Landing />}
          />
          <Route
            path="/login"
            element={userEmail && userEmail !== '' ? <Navigate to="/home" /> : <Login />}
          />
          <Route
            path="/home"
            element={!userEmail || userEmail === '' ? <Navigate to="/login" /> : <Home />}
          />
          <Route
            path="/profile"
            element={!userEmail || userEmail === '' ? <Navigate to="/login" /> : <Profile />}
          />
          <Route
            path="/mytasks"
            element={!userEmail || userEmail === '' ? <Navigate to="/login" /> : <MyTasks />}
          />
          <Route
            path="/team/:teamId"
            element={!userEmail || userEmail === '' ? <Navigate to="/login" /> : <TeamMain />}
          />
          <Route
            path="/team/:teamId/ranking"
            element={!userEmail || userEmail === '' ? <Navigate to="/login" /> : <Ranking />}
          />
        </Routes>
      </Router>
    </div>
  );
}


export default App;
