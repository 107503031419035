import React from 'react'
import Nav from '../components/Nav'
import { useEffect, useState } from 'react'
import { collection, query, where, getDocs, doc, setDoc, addDoc, updateDoc, getDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { db } from '../firebase/init';
import Loading from '../components/Loading';

export default function MyTasks() {

    const email = localStorage.getItem('email');
    const [userInfo, setUserInfo] = useState([]);
    const usersInfoCollectionRef = collection(db, 'userInfo');
    const [userInfoId, setUserInfoId] = useState("");
    const [myTasks, setMyTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [wipTasks, setWipTasks] = useState([]);
    const [finishedTasks, setFinishedTasks] = useState([]);
    const [selection, setSelection] = useState(1);
    const [currentTasks, setCurrentTasks] = useState([]);


    // get all the teams that the user is a part of
    useEffect(() => {
        const getUsersInfo = async () => {
            const usersInfoCollectionRef = collection(db, 'userInfo');
            const q = query(usersInfoCollectionRef, where('email', '==', email));

            try {
                const querySnapshot = await getDocs(q);
                const userInfoData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                // If userInfo exists, set the userInfo state to the existing document
                setUserInfoId(userInfoData[0].id); // Set the userInfoId state to the existing document's ID
                setUserInfo(userInfoData[0]); // Set the userInfo state to the existing document

                setMyTasks(userInfoData[0].myTasks);
                setLoading(false);

            } catch (error) {
                console.error('Error fetching/creating user info:', error);
            }
        };

        getUsersInfo();
    }, [email]);

    useEffect(() => {
        if (myTasks.length > 0) {
            // Filter tasks into 'wip' and 'finished' arrays
            const wipTasks = myTasks.filter((task) => task.status === 'wip');
            const finishedTasks = myTasks.filter((task) => task.status === 'finished');

            // Update the state with the sorted arrays
            setWipTasks(wipTasks);
            setFinishedTasks(finishedTasks);
        }
    }, [myTasks]);

    useEffect(() => {
        document.title = 'My Tasks | collabhour'
    }, [])

    // Set the currentTasks state to the selected array
    useEffect(() => {
        if (selection === 1) {
            setCurrentTasks(myTasks);
        } else if (selection === 2) {
            setCurrentTasks(wipTasks);
        } else if (selection === 3) {
            setCurrentTasks(finishedTasks);
        }
    }, [selection, myTasks, wipTasks, finishedTasks]);

    return (
        <div>
            <Nav />

            <div className='px-6 py-10'>
                <h1 className='text-3xl font-bold'>My Tasks</h1>
                <div className='flex items-center my-4'>
                    <p className={`mx-3 hover:cursor-pointer ${selection === 1 && "font-bold text-blue-400"}`} onClick={() => setSelection(1)}>All ({myTasks.length})</p>
                    <div className="h-5 rounded-md w-1 bg-base-300 bor"></div>
                    <p className={`mx-3 hover:cursor-pointer ${selection === 2 && "font-bold text-orange-300"}`} onClick={() => setSelection(2)}>
                        <span className='hidden md:block'>Work in progress ({wipTasks.length})</span>
                        <span className='block md:hidden'>w.i.p. ({wipTasks.length})</span>
                    </p>
                    <p className={`mx-3 hover:cursor-pointer ${selection === 3 && "font-bold text-green-600"}`} onClick={() => setSelection(3)}>Finished ({finishedTasks.length})</p>
                </div>
                {loading && <Loading />}
                <div className='mt-2'>
                    {currentTasks.length > 0 && currentTasks.map((task, index) => (
                        <Link to={`/team/${task.teamsID}`} key={index}>

                            <div className="hover:bg-base-200 sm:flex justify-between py-2 border-base-300 border-2 border-solid rounded-lg my-2 px-4 items-center sm:flex-wrap grid grid-cols-2">
                                <div>
                                    {task.status === "wip" && (
                                        <p className="badge badge-warning md:mb-2">Work in progress</p>
                                    )}
                                    {task.status === "finished" && (
                                        <p className="badge badge-success md:mb-2">Finished</p>
                                    )}

                                    <p className="font-bold text-sm hidden md:block">
                                        {task.teamName} • {task.company}
                                    </p>
                                    <p className="text-lg">{task.name}</p>
                                </div>
                                <div className='flex justify-end'>
                                    <button className="btn btn-primary">Go to team</button>
                                </div>
                            </div>


                        </Link>
                    ))}


                </div>
            </div>
        </div >
    )
}
