import React from 'react'

export default function Loading() {
    return (
        <div className="flex justify-center items-center h-screen">
            <div className="text-center">
                <span className="loading loading-spinner loading-lg block mr-2"></span>
            </div>
        </div>

    )
}
